// 匹配中文的正则
const zhMatchReg = /[\u4e00-\u9faf]+/
// 针对不需要机翻的部分，项目内部需要打上对应属性标记的tag名称
const forbidSdkTransTagName = 'data-no-translate'
// 翻译语言列表
const localeWhiteList = ['en', 'ja']
// 翻译语言
let locale
// 接口地址
const interfaceUrl = window.location.hostname === 'ad.oceanengine.com'
  ? '/overture/meta_translation/' : 'https://ad.oceanengine.com/overture/meta_translation/'
// indexedDB 数据库名称
const iDBName = 'translateDB'
// indexedDB 中文->英文映射表
const iDBZh2EnTableName = 'trans2EnTable'
// indexedDB 中文->日文映射表
const iDBZh2JaTableName = 'trans2JaTable'
// indexedDB 当前读取 & 写入表
let iDBCurrentTableName
// indexedDB 当前版本
let iDBVersion = 1
// 翻译sdk标识
const SCRIPT_SRC_TYPE = 'oceanengine-translate-sdk'

// 读取标志位
let flag = false
// 是否自定义配置
let dataCustomConfig = false
// 用户自定义配置内容
let customConfig = {
  blackClassLists: []
}
// 节流时间控制
const throttleTime = 300
// 已存在的翻译内容hash表
let existMapRelation = {
  en: {},
  ja: {}
}
// 初始文本节点的列表
let initialList = []
// 正在翻译过程中的文本节点的列表
let translatingList = []

export default function () {
  function init () {
    // 校验有效script
    let scripts = toArray(document.scripts)
    const validScript = scripts.filter(script => script.attributes['data-src-type'] && getAttr(script, 'data-src-type') === SCRIPT_SRC_TYPE)[0]
    if (validScript) {
      if (validScript.attributes && validScript.attributes.hasOwnProperty('data-custom-config')) {
        dataCustomConfig = true
      } else {
        locale = getCookieItem('locale') || queryToJson().locale || ''
        bindObserver()
      }
    }
  }
  function initTransSdk (config = {}) {
    if (dataCustomConfig) {
      locale = config.locale
      customConfig.blackClassLists = objType(config.blackClassLists) === 'Array'
        ? customConfig.blackClassLists.concat(config.blackClassLists)
        : customConfig.blackClassLists
      bindObserver()
    }
  }
  function bindObserver () {
    try {
      // 限制白名单内的翻译语言才可注册主逻辑
      if (localeWhiteList.indexOf(locale) > -1) {
        iDBCurrentTableName = locale === 'en' ? iDBZh2EnTableName : iDBZh2JaTableName
        // 检测模块及子元素变化
        const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
        // 检测是否支持promise
        let supportPromise
        try {
          supportPromise = typeof Promise !== 'undefined' && Promise.toString().indexOf('[native code]') !== -1
          if (!supportPromise) {
            let promiseInstance = new Promise((resolve, reject) => {})
            supportPromise = typeof promiseInstance.then === 'function'
          }
        } catch (e) {
          console.log(e)
        }

        if (MutationObserver && supportPromise) {
          readDB().then(() => {
            flag = true
            main()
          })
          // 增加节流配置
          const updateRecursion = throttle(main, throttleTime)
          // 创建观察者对象
          const observer = new MutationObserver(mutations => {
            updateRecursion()
          })
          // 配置观察选项
          let config = { attributes: true, childList: true, characterData: true, subtree: true }

          // 传入目标节点和观察选项
          observer.observe(document.documentElement || document.body, config)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  return { init, initTransSdk }
}

function main () {
  if (!flag) return
  // 已存在的翻译文案（机翻）
  const existLocaleTransTexts = Object.values(existMapRelation[locale])
  // 项目中已存在的翻译文案（人工翻译的部分）
  const existBpTransJaTexts = window.bp_translation_zh_to_ja && objType(window.bp_translation_zh_to_ja) === 'Object' && Object.values(window.bp_translation_zh_to_ja) || []
  recursion(document.documentElement)
  getTranslateText()
  /**
   * 获取全量文本节点列表，存储在内置数组initialList中
   * @param obj 原生DOM节点
   * @returns null
   */
  function recursion (obj) {
    if (!obj) return

    const childrenList = obj.childNodes
    if (childrenList && childrenList.length) {
      Array.prototype.forEach.call(childrenList, dom => {
        if (['SCRIPT', 'STYLE', 'desc'].includes(dom.nodeName)) return
        if (dom && dom.nodeType === 1 && (
          dom.attributes.hasOwnProperty('data-no-translate') ||
          customConfig.blackClassLists.some(clsName => hasClass(dom, clsName))
        )) {
          return
        }

        let nodeTextByTrim
        if (dom.nodeType === 3 && dom.nodeValue) { // 处理文本节点
          nodeTextByTrim = dom.nodeValue.trim()
        } else if (dom.nodeName === 'INPUT') { // 处理input标签
          nodeTextByTrim = handleInputTag(dom).curInputValueTrim
        }
        // 文案存在 && 包含中文
        if (nodeTextByTrim && nodeTextByTrim.match(zhMatchReg)) {
          if (locale === 'ja' && (
            existLocaleTransTexts.includes(nodeTextByTrim) ||
            existBpTransJaTexts.includes(nodeTextByTrim)
          )) {
            return
          }
          if (initialList.includes(dom) || translatingList.includes(dom)) {
            return
          }
          initialList.push(dom)
        }
        recursion(dom)
      })
    }
  }

  function getTranslateText () {
    while (initialList.length) {
      let curHandleList = initialList.splice(0, 64)
      translatingList.push(...curHandleList)
      trans(curHandleList)
        .then((data = {}) => {
          data.data.forEach(item => {
            existMapRelation[locale][item.text] = item.translated_text
          })
          writeDB(data.data)
          replaceText(curHandleList)
        }).catch(e => {
          replaceText(curHandleList)
        })
    }
    function trans (arr) {
      return new Promise((resolve, reject) => {
        arr = arr.map(dom => {
          if (dom.nodeType === 1 && dom.nodeName === 'INPUT') {
            return handleInputTag(dom).curInputValueTrim
          }
          return dom.nodeValue.trim()
        }).filter(val => !existMapRelation[locale][val])
        if (!arr.length) {
          reject()
          return
        }
        try {
          ajax('POST', {
            parse: true,
            url: interfaceUrl,
            headers: {
              'X-CSRFToken': getCookieItem('csrftoken'),
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              text_list: arr,
              trg_lang: locale
            }),
            success: function (data) {
              resolve(data)
            }
          })
        } catch (e) {
          console.log(e)
        }
      })
    }
    function replaceText (arr) {
      arr.forEach(dom => {
        if (dom.nodeType === 3) {
          const nodeTextByTrim = dom.nodeValue.trim()
          if (nodeTextByTrim && existMapRelation[locale][nodeTextByTrim]) {
            dom.replaceData(dom.nodeValue.indexOf(nodeTextByTrim), nodeTextByTrim.length, existMapRelation[locale][nodeTextByTrim])
          }
        } else if (dom.nodeType === 1) {
          if (dom.nodeName === 'INPUT') {
            const { curInputValueTrim, attributeName } = handleInputTag(dom)
            curInputValueTrim && existMapRelation[locale][curInputValueTrim] && dom.setAttribute(attributeName, existMapRelation[locale][curInputValueTrim])
          }
        }
        translatingList.splice(translatingList.indexOf(dom), 1)
      })
    }
  }
}


/**
 * 处理input标签
 * input类型为button时，对value属性进行修改，否则对placeholder进行修改
 * @param inputDom 传入原生dom节点
 * @returns 当前dom的对应属性值
 */
function handleInputTag (inputDom) {
  const curInputType = inputDom.getAttribute('type')
  const attributeName = curInputType && curInputType.toLowerCase() === 'button' ? 'value' : 'placeholder'
  const curInputValue = inputDom.getAttribute(attributeName)
  const curInputValueTrim = curInputValue && curInputValue.trim()
  return { curInputValueTrim, attributeName }
}

function readDB () {
  return new Promise((resolve, reject) => {
    if (!window.indexedDB) {
      resolve()
      return
    }
    let openRequest = window.indexedDB.open(iDBName, iDBVersion)

    openRequest.onsuccess = (e) => {
      try {
        const db = e.target.result
        const store = db.transaction(iDBCurrentTableName).objectStore(iDBCurrentTableName)
        store.openCursor().onsuccess = (event) => {
          try {
            let cursor = event.target.result
            if (cursor) {
              existMapRelation[locale][cursor.key] = cursor.value.trans
              cursor.continue()
            } else {
              resolve()
            }
          } catch (e) {
            resolve()
          }
        }
        store.openCursor().onerror = (event) => resolve()
      } catch (e) {
        window.indexedDB.deleteDatabase(iDBName)
        resolve()
      }
    }
    openRequest.onerror = (e) => resolve()
  })
}

function writeDB (arr = []) {
  if (!window.indexedDB) return
  let openRequest = window.indexedDB.open(iDBName, iDBVersion)

  openRequest.onupgradeneeded = (e) => {
    const db = e.target.result
    db.createObjectStore(iDBZh2EnTableName, { keyPath: 'source', autoIncrement: false })
    db.createObjectStore(iDBZh2JaTableName, { keyPath: 'source', autoIncrement: false })
  }

  openRequest.onsuccess = (e) => {
    const db = e.target.result
    const tx = db.transaction([iDBCurrentTableName], 'readwrite')
    const store = tx.objectStore(iDBCurrentTableName)
    // 保存数据
    arr.forEach(item => {
      let reqAdd = store.put({ source: item.text, trans: item.translated_text })
      reqAdd.addEventListener('success', e => {})
      reqAdd.addEventListener('error', e => {})
    })
  }

  openRequest.onerror = (e) => {}
}

/**
 * 节流函数 按最早时间触发 响应最早动作 保证指定间隔执行一次
 * @param action 回调动作
 * @param interval 间隔时间
 * @returns {function(...[*]=)}
 */
function throttle (action, interval = 100) {
  let timer = 0
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        action && action.apply(null, args)
        timer = 0
      }, interval)
    }
  }
}

/**
 * 节流函数 按最新时间触发 响应最新动作 连续动作只执行一次
 * @param action 回调动作
 * @param interval 间隔时间
 * @returns {function(...[*]=)}
 */
function debounce (action, interval = 100) {
  let timer = 0
  return (...args) => {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      action && action.apply(null, args)
    }, interval)
  }
}

/**
 * 将url地址中的query字符串转化为相应json对象
 * @param url 地址
 * @returns {function(...[*]=)}
 */
function queryToJson (url) {
  url = url || window.location.href
  let params = url.slice(url.indexOf('?') + 1)
  let obj = {}
  params.split('&').forEach(item => {
    let row = item.split('=')
    if (row.length === 2) {
      obj[row[0]] = decodeURIComponent(row[1])
    }
  })
  return obj
}

function getCookieItem (sKey) {
  return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
}

function ajax (type, options, async = true) {
  let xmlHttp = createHttp()
  if (!xmlHttp) {
    return
  }
  xmlHttp.open(type, options.url, async)
  options.headers && Object.keys(options.headers).forEach(headerName => {
    xmlHttp.setRequestHeader(headerName, options.headers[headerName])
  })
  xmlHttp.withCredentials = true
  if (options.headers && xmlHttp.overrideMimeType && options.headers['Content-Type']) {
    xmlHttp.overrideMimeType(options.headers['Content-Type'])
  }
  xmlHttp.onreadystatechange = (event) => {
    if (event.target.readyState === 4) {
      let resp = {}
      if (options.parse) {
        try {
          resp = JSON.parse(event.target.responseText)
        } catch (e) {
          console.log(e)
        }
      }
      if (event.target.status === 200) {
        options.success && options.success(options.parse ? resp : event.target.responseText)
      } else {
        options.error && options.error(options.parse ? resp : event.target.responseText)
      }
    }
  }
  xmlHttp.send(options.data || null)
}

function createHttp () {
  let xmlhttp = null
  try {
    xmlhttp = new XMLHttpRequest()
  } catch (e) {
    try {
      xmlhttp = ActiveXobject('Msxml12.XMLHTTP')
    } catch (e1) {
      try {
        xmlhttp = ActiveXobject('Microsoft.XMLHTTP')
      } catch (e2) {
        console.log(e2)
      }
    }
  }
  return xmlhttp
}

function objType (obj) {
  return Object.prototype.toString.call(obj).match(/^\[object (.*)\]$/)[1]
}

function toArray (obj) {
  return Array.prototype.slice.call(obj)
}

function getAttr (node, key, defaultValue) {
  if (node && node.attributes[key]) {
    return node.attributes[key].value.trim()
  }
  return defaultValue || ''
}

function hasClass(el, cls) {
  if (!el || !cls) return false
  if (cls.indexOf(' ') !== -1) {
    throw new Error('className should not contain space.')
  }
  if (el.classList) {
    return el.classList.contains(cls)
  } else {
    return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
  }
}